import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gbpCurrency',
  standalone: true
})
export class GbpCurrencyPipe implements PipeTransform {
  transform(value: string): string {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return '';
    }
    return num >= 1 ? `£${num}` : `${Math.round(num * 100)}p`;
  }
}
