import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
  standalone: true
})
export class PluralizePipe implements PipeTransform {
  transform(text: string, number: number): string {
    return number > 1 ? `${text}s` : text;
  }
}
