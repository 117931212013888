<div
  class="ticket-card {{ ticket.type_card }} ticket-card--{{ ticketStyle }} {{
    ticketSize
  }}"
  [class.is-animated]="isAnimated"
  [class.auto-reveal]="autoReveal"
>
  <div class="ticket-card__content">
    <ng-container
      *ngIf="ticket.type_card === ticketCardType.Win && ticket.image"
    >
      <img [src]="ticket.image" alt="{{ ticket.prize_name }}" />
    </ng-container>

    <span
      class="credit-text"
      *ngIf="ticket.type_card === ticketCardType.Credit"
    >
      <strong>{{ ticket.market_value | gbpCurrency }}</strong>
      Site Credit
    </span>

    <span
      class="loss-text"
      *ngIf="
        ticket.type_card === ticketCardType.Loss &&
        ticketStyle === ticketCardStyle.Default
      "
    >
      {{ ticket.ticket_number }}</span
    >
    <span
      class="loss-text"
      *ngIf="
        ticket.type_card === ticketCardType.Loss &&
        ticketStyle !== ticketCardStyle.Default
      "
    >
      <p class="top-line">No</p>
      <span class="down-line"> Win</span>
    </span>
  </div>

  <div
    class="ticket-card__footer"
    *ngIf="
      showTicketNumber &&
      (ticket.type_card !== ticketCardType.Loss ||
        ticketStyle !== ticketCardStyle.Default)
    "
  >
    {{ ticket.ticket_number }}
  </div>
</div>
