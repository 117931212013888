import { Pipe, PipeTransform } from '@angular/core';
import { RaffleInstantType } from '@core/models/raffle-instant-type.enum';

@Pipe({
  name: 'instantLabel',
  standalone: true
})
export class InstantLabelPipe implements PipeTransform {
  transform(type: string): string {
    let label: string;

    switch (type) {
      case RaffleInstantType.Regular:
        label = 'Instant Win';
        break;
      case RaffleInstantType.Scratch:
        label = 'Instant Scratch';
        break;
      case RaffleInstantType.Flip:
        label = 'Instant Flip';
        break;
      case RaffleInstantType.Spin:
        label = 'Instant Spin';
        break;
      default:
        label = 'Instant Win';
        break;
    }

    return label;
  }
}
